import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MultiDialog from 'components/MultiDialog';
import { snackbar } from 'components/Snackbar';
import { IntPolicyDto, IntPolicyUsageDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dashboardPolicyApi } from 'services/dashboardPolicy.service';
import { useGet } from 'shared/api/hooks';
import { useStores } from 'store';

interface IProps {
  isOpen: boolean;
  close: () => void;
  policy: IntPolicyDto;
}

const ConfirmDeletePolicy: React.FC<IProps> = ({ isOpen, close, policy }) => {
  const {
    policyStore: { deletePolicy },
  } = useStores();
  const { t } = useTranslation('policy');

  const [usages, setUsages] = useState<IntPolicyUsageDto[]>([]);
  const [isUnauthorized, setIsUnauthorized] = useState<boolean>(false);
  const [getUsage, isLoading] = useGet(dashboardPolicyApi.getPolicyUsage);

  const fetchUsageData = useCallback(async () => {
    const resp = await getUsage(policy.policyId);

    if (resp.status === 200 || resp.status === 204) {
      setUsages(resp.data || []);
    } else if (resp.status === 403) {
      setIsUnauthorized(true);
    } else {
      snackbar(t('error.usage'), { variant: 'error' });
    }
  }, [getUsage, policy, t]);

  useEffect(() => {
    if (isOpen) {
      fetchUsageData();
    }
  }, [fetchUsageData, isOpen]);

  return (
    <MultiDialog
      title={t('action.delete_policy')}
      type={isUnauthorized ? 'simple' : 'confirm'}
      show={isOpen}
      isLoading={isLoading}
      onClose={() => {
        close();
        setUsages([]);
      }}
      onConfirm={() => {
        deletePolicy(policy);
        close();
      }}
      acceptButtonText={t('common:action.yes')}
      cancelButtonText={t('common:action.cancel')}
    >
      {isUnauthorized ? (
        <Typography marginBottom={4}>{t('delete_not_allowed')}</Typography>
      ) : (
        <>
          {usages.length > 0 && (
            <>
              <Typography marginBottom={3}>{`${t(
                'policy_is_used_warning'
              )}:`}</Typography>
              <Box maxHeight={450} overflow="scroll">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight="bold" variant="body1">
                          {t('common:customer')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">
                          {t('dashboard:base')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">
                          {t('dashboard:action.widget.base')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usages.map(u => (
                      <TableRow key={u.dashboardComponentId}>
                        <TableCell>{u.customerName}</TableCell>
                        <TableCell>{u.dashboardName}</TableCell>
                        <TableCell>{u.dashboardComponentTitle}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </>
          )}
          <Typography marginBottom={4}>
            {t('confirm_delete', { name: policy.name })}
          </Typography>
        </>
      )}
    </MultiDialog>
  );
};

export default observer(ConfirmDeletePolicy);
