import {
  IntAssetIdentifierDto,
  IntAssetPropertiesDto,
  IntAssetTableDto,
  IntAttributeDto,
  IntCreateAssetDto,
  IntFilterOptionsResponseDto,
  IntServiceDataSpecificationFilterDto,
} from 'generated';
import {
  DeleteEndpoint,
  GetEndpoint,
  PostEndpoint,
  PutEndpoint,
  TableEndpoint,
} from 'shared/interfaces/api';
import { assetDemoData } from './demoData/assetDemoData';

interface IUnprocessedDataSeries {
  label: string;
  values: number[];
  valueSuffix: string;
}

export interface ITempAssetLast7DaysUnprocessedDto {
  series: IUnprocessedDataSeries[];
}

export interface ITempAssetEventDto {
  date: Date;
  values: string[];
}

export interface ITempAssetEventsFeedDto {
  eventTypes: string[];
  events: ITempAssetEventDto[];
}

export interface ITempConnectionInformation {
  displayName: string;
  imei: string;
  connectivityStatus: string;
  powerSource: string;
  batteryStatus: number;
}

export interface ITempAssetConnectionsInformationDto {
  assets: ITempConnectionInformation[];
}

export interface TmpFilterOptionsResponseDto {
  options: IntFilterOptionsResponseDto[];
  hasMore: boolean;
}

interface AssetAPI {
  getAll: TableEndpoint<IntAssetTableDto>;
  getAssetProperties: GetEndpoint<string, IntAssetPropertiesDto>;
  getLast7DaysUnprocessedData: GetEndpoint<
    string,
    ITempAssetLast7DaysUnprocessedDto
  >;
  getEventsFeed: GetEndpoint<string, ITempAssetEventsFeedDto>;
  updateGeneralInformation: PutEndpoint<
    string,
    IntAssetPropertiesDto,
    IntAssetPropertiesDto
  >;
  getIdentifiers: (assetId: string) => TableEndpoint<IntAssetIdentifierDto>;
  createIdentifier: PostEndpoint<string, IntAssetIdentifierDto>;
  createAsset: PostEndpoint<undefined, IntCreateAssetDto>;
  deleteIdentifier: DeleteEndpoint<{
    assetId: string;
    assetIdentifierId: string;
  }>;
  getAttributes: (assetId: string) => TableEndpoint<IntAttributeDto>;
  getAttributeOptions: TableEndpoint<IntFilterOptionsResponseDto>;
  upsertAttribute: PutEndpoint<string, IntAttributeDto, IntAttributeDto>;
  deleteAttribute: DeleteEndpoint<{
    assetId: string;
    attributeId: string;
  }>;
  deleteAsset: DeleteEndpoint<string>;
  getAllAttributeKeys: GetEndpoint<undefined, string[]>;
  getFilteredAssetCount: PostEndpoint<
    undefined,
    IntServiceDataSpecificationFilterDto[],
    number
  >;
  unshare: DeleteEndpoint<string, string[]>;
}

const baseUrl = '/api/assets';

export const assetAPI: AssetAPI = {
  getAll: {
    url: `${baseUrl}/table`,
    mockData: assetDemoData.getAllAssets,
  },
  getAssetProperties: {
    url: (assetId: string) => `${baseUrl}/${assetId}`,
    mockData: assetDemoData.getAsset,
  },
  getLast7DaysUnprocessedData: {
    url: (assetId: string) => `${baseUrl}/${assetId}/unprocesseddata`,
    mockData: assetDemoData.getLast7DaysUnprocessedData,
  },
  getEventsFeed: {
    url: (assetId: string) => `${baseUrl}/${assetId}/events`,
    mockData: assetDemoData.getEventsFeed,
  },
  updateGeneralInformation: {
    url: (assetId: string) => `${baseUrl}/${assetId}`,
    mockData: (params, data) => assetDemoData.updateGeneralInformation(data),
  },
  getIdentifiers: assetId => ({
    url: `${baseUrl}/${assetId}/identifiers`,
    mockData: assetDemoData.getIdentifiers,
  }),
  createIdentifier: {
    url: (assetId: string) => `${baseUrl}/${assetId}/identifiers`,
    mockData: assetDemoData.createIdentifier,
  },
  createAsset: {
    url: `${baseUrl}`,
    mockData: assetDemoData.createAsset,
  },
  deleteAsset: {
    url: assetId => `${baseUrl}/${assetId}`,
    mockData: assetDemoData.deleteAsset,
  },
  deleteIdentifier: {
    url: ({ assetId, assetIdentifierId }) =>
      `${baseUrl}/${assetId}/identifiers/${assetIdentifierId}`,
    mockData: assetDemoData.deleteIdentifier,
  },
  getAttributes: assetId => ({
    url: `${baseUrl}/${assetId}/attributes`,
    mockData: assetDemoData.getAttributes,
  }),
  getAttributeOptions: {
    url: `${baseUrl}/attributes/options`,
    mockData: assetDemoData.getAttributeOptions,
  },
  upsertAttribute: {
    url: (assetId: string) => `${baseUrl}/${assetId}/attributes`,
    mockData: () => ({ status: 200 }),
  },
  deleteAttribute: {
    url: ({ assetId, attributeId }) =>
      `${baseUrl}/${assetId}/attributes/${attributeId}`,
    mockData: ({ attributeId }) => assetDemoData.deleteAttribute(attributeId),
  },
  getAllAttributeKeys: {
    url: `${baseUrl}/attributes/keys`,
    mockData: assetDemoData.getAllAttributeKeys,
  },
  getFilteredAssetCount: {
    url: `${baseUrl}/filteredAssetCount`,
    mockData: () => ({ status: 200, data: 1337 }),
  },
  unshare: {
    url: (assetId: string) => `${baseUrl}/${assetId}/unshare`,
    mockData: () => ({ status: 200 }),
  },
};
