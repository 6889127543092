import { Box, Grid } from '@mui/material';
import { AutoComplete } from 'components/Inputs';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, RefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';

interface IProps {
  topRightRef: RefObject<HTMLDivElement> | null;
  policy: string | null;
  handleChange: Dispatch<SetStateAction<string | null>>;
}

const PolicyList: React.FC<IProps> = ({
  topRightRef,
  policy,
  handleChange,
}) => {
  const { t } = useTranslation('policy');
  const {
    policyStore: { policies = [] },
  } = useStores();

  return (
    <>
      {policies.length === 0 && (
        <Box textAlign="center">{t('no_policies')}</Box>
      )}
      <Grid
        container
        mb={2}
        paddingY={4}
        paddingX={9}
        style={{ backgroundColor: '#f4f4f9' }}
      >
        <Grid item sm={4}>
          <AutoComplete
            label={t('select_policy')}
            options={policies}
            getOptionValue={opt => opt.policyId}
            getOptionLabel={opt => opt.name}
            value={policy}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sm={8}
          ref={topRightRef}
        />
      </Grid>
    </>
  );
};

export default observer(PolicyList);
