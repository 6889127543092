import { Role } from 'components/Auth/Role';
import {
  IntAddPhoneNumberDto,
  IntAttachmentDto,
  IntEditUserProfileDto,
  IntGoogleAuthenticatorDto,
  IntGoogleAuthenticatorEnableDto,
  IntLoginFormDto,
  IntLoginResult,
  IntLoginResultDto,
  IntSendProviderCodeDto,
  IntUserDto,
  IntUserFeaturesRolesDto,
  IntVerifyCodeDto,
  UserRoleAccessLevel,
} from 'generated';
import { IResponse } from 'shared/interfaces/api';
import { rnd } from 'utils';

const getMockUser = (userName: string): IntUserDto => {
  const firstName = 'Johan';
  const lastName = 'Svensson';

  return {
    userName,
    address: 'Stjärntorget 1',
    birthDate: 0,
    city: 'SOLNA',
    countryISO3166: 'SE',
    customerId: rnd.guid(),
    countryName: 'Sweden',
    displayName: userName,
    dealerId: rnd.guid(),
    dealerName: `${rnd.firstName()} ${rnd.lastName()}`,
    customerName: rnd.customerName(),
    customerLogo: '',
    firstName,
    middleName: '',
    lastName,
    fullName: `${firstName} ${lastName}`,
    id: rnd.guid(),
    isVerified: false,
    lastActivityDate: 0,
    lastActivityIP: rnd.ip(),
    profilePicturePath: '',
    registerIP: rnd.ip(),
    userId: rnd.guid(),
    groups: [
      { groupId: '1', groupName: 'admin 1', fromTemplate: false, roles: [] },
      { groupId: '2', groupName: 'admin 2', fromTemplate: false, roles: [] },
    ],
    utcDiff: 0,
    zipCode: '11262',
    phoneNumber: rnd.phoneNumber(),
    phoneNumberConfirmed: true,
    languageCulture: localStorage.getItem('languageCulture') || 'en-US',
    email: rnd.email(),
    site: 'Any Company AB\nStockholm',
    googleAuthenticatorSecretKey: 'Google Key',
    twoFactorEnabled: false,
    isGoogleAuthenticatorEnabled: false,
    emailConfirmed: rnd.bool(),
    isEmailVerificationRequired: rnd.bool(),
    lockoutEnabled: true,
  };
};

class AccountDemoData {
  private _userData: IntUserDto = getMockUser('Demo user');

  changeUserInfo = (
    params: string | undefined,
    updatedUserInfo: IntEditUserProfileDto | undefined
  ): IResponse => {
    let profilePicData = {};

    if (updatedUserInfo?.imageData) {
      profilePicData = { profilePicturePath: updatedUserInfo.imageData };
    }

    this._userData = {
      ...this._userData,
      ...updatedUserInfo,
      ...profilePicData,
    };

    return { status: 200 };
  };

  login = (
    params: undefined,
    loginForm: IntLoginFormDto | undefined
  ): IResponse<IntLoginResult> => {
    if (loginForm?.username) {
      localStorage.setItem('demoUser', loginForm?.username);
    }
    return {
      status: 200,
      data:
        loginForm?.username === '2fa'
          ? IntLoginResult.RequiresVerification
          : IntLoginResult.Success,
    };
  };

  logout = () => {
    localStorage.removeItem('demoUser');
    return {
      status: 200,
    };
  };

  getUser = (userName?: string): IResponse<IntUserDto> => ({
    status: 200,
    data: this._userData,
  });

  getUserAuthorization = (): IResponse<IntUserFeaturesRolesDto> => {
    const demoUserName = getDemoUserName();

    if (!demoUserName) {
      return {
        status: 403,
      };
    }

    return {
      status: 200,
      data: {
        userModel: getMockUser(demoUserName),
        roles: Object.values(Role).filter(
          role => role !== Role.RoleNameStaticDemo // Disable static role in demo since it removes all demo references.
        ),
        features: [],
        activeSubPortalDomainAlias: '',
        isImpersonating: false,
        roleAndAccessLevels: Object.fromEntries(
          Object.values(Role)
            .filter(role => role !== Role.RoleNameStaticDemo)
            .map(value => [value, UserRoleAccessLevel.Global])
        ),
      },
    };
  };

  sendCode = (
    params: undefined,
    provider: IntSendProviderCodeDto | undefined
  ): IResponse => ({
    status: provider?.selectedProvider === 'Broken provider' ? 500 : 200,
  });

  verifyCode = (
    params: undefined,
    formValues: IntVerifyCodeDto | undefined
  ): IResponse<IntLoginResultDto> => ({
    status: formValues?.code === 'error' ? 500 : 200,
  });

  get2faProviders = (): IResponse<string[]> => ({
    status: 200,
    data: ['SMS', 'Google Authenticator', 'Broken provider'],
  });

  uploadProfilePicture = (): IResponse<IntAttachmentDto[]> => ({
    status: 200,
    data: [
      {
        id: '1',
        fileName: 'Mocked_file',
        size: 5000,
        content: 'Hello World',
        mimeType: 'text/plain',
      },
    ],
  });

  toggleTwoFactorAuthentication = () => {
    this._userData = {
      ...this._userData,
      ...{ twoFactorEnabled: !this._userData.twoFactorEnabled },
    };

    return {
      status: 200,
    };
  };

  getGoogleAuthenticatorDetails = (): IResponse<IntGoogleAuthenticatorDto> => ({
    status: 200,
    data: {
      barcodeUrl:
        'https://qrcode.kaywa.com/img.php?s=4&amp;d=otpauth%3a%2f%2ftotp%2fpetasl%3fsecret%3dDA5OGDB7I6OPAKIGZQFOWNAGJLGSB74P%26issuer%3dMessify',
      secretKey: '1234567',
    },
  });

  enableGoogleAuthenticator = (
    params: IntGoogleAuthenticatorEnableDto | undefined
  ) => {
    this._userData = {
      ...this._userData,
      ...{ isGoogleAuthenticatorEnabled: true },
    };

    return {
      status: 200,
    };
  };

  disableGoogleAuthenticator = () => {
    this._userData = {
      ...this._userData,
      ...{ isGoogleAuthenticatorEnabled: false },
    };

    return {
      status: 200,
    };
  };

  addPhoneNumber = (
    params: undefined,
    data: IntAddPhoneNumberDto | undefined
  ) => {
    this._userData = {
      ...this._userData,
      ...{ phoneNumber: data?.phoneNumber || '', phoneNumberConfirmed: true },
    };

    return {
      status: 200,
    };
  };

  deletePhoneNumber = () => {
    this._userData = {
      ...this._userData,
      ...{ phoneNumber: '', phoneNumberConfirmed: false },
    };

    return {
      status: 200,
    };
  };
}

const getDemoUserName = () => localStorage.getItem('demoUser');

export const accountDemoData = new AccountDemoData();
