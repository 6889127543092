import AtlasIcon from 'components/AtlasIcon';
import { Role } from 'components/Auth/Role';
import OptionalTooltip from 'components/OptionalTooltip';
import { IntMenuItemDto } from 'generated';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useStores } from 'store';
import { useEllipsis } from 'utils';
import { INavigationMenuState } from '../useNavMenuState';
import CollapseParent from './CollapseParent';
import MenuItemButton from './MenuItemButton';
import MenuItemContainer, {
  IMenuItemContainerProps,
} from './MenuItemContainer';
import MenuItemIcon from './MenuItemIcon';
import MenuItemText from './MenuItemText';

interface IProps {
  item: IntMenuItemDto;
  isRoot?: boolean;
  navMenuState: INavigationMenuState;
  variant?: 'categoryHeader' | 'collapseParent' | 'default';
  endContent?: JSX.Element;
  inset?: boolean;
}

const MenuItem: React.FC<IProps> = ({
  item,
  isRoot,
  navMenuState,
  variant = 'default',
  endContent: propEndContent,
  inset,
}) => {
  const { layoutStore, authStore } = useStores();
  const hasChildren = item.children.length > 0;
  const isActive = item === navMenuState.activeMenuItem;

  const containerProps: IMenuItemContainerProps = {
    color: isActive ? 'active' : 'default',
    url: item.route,
    inset,
    dataCy: item.menuItemId,
  };

  const ellipsisElem = useRef<HTMLDivElement>(null);
  const { requiresEllipsis } = useEllipsis(ellipsisElem);

  let endContent = propEndContent;

  // Root items with children lead to sub-categories
  if ((hasChildren || !item.route) && isRoot && variant !== 'categoryHeader') {
    containerProps.onClick = () => navMenuState.setActiveRootItem(item);

    if (item === navMenuState.activeRootItem) {
      containerProps.color = 'categoryHeader';
    }

    endContent = <AtlasIcon type="ChevronRight" />;
  }

  let startContent: JSX.Element;

  if (variant === 'categoryHeader') {
    if (!isActive) {
      containerProps.color = 'categoryHeader';
    }

    // Headers "back" button
    startContent = (
      <MenuItemButton
        onClick={e => {
          e.preventDefault();
          navMenuState.setActiveRootItem(undefined);
        }}
      >
        <AtlasIcon type="ChevronLeft" />
      </MenuItemButton>
    );
  } else {
    startContent = <MenuItemIcon iconType={item.icon} />;
  }

  if (variant !== 'collapseParent' && hasChildren && !isRoot) {
    return <CollapseParent item={item} navMenuState={navMenuState} />;
  }

  // Temporary solution to quickly create dashboards for menu items created by product templates
  // Remove when dashboards are copied in the template process
  if (
    variant !== 'collapseParent' &&
    !hasChildren &&
    !isRoot &&
    !item.route &&
    authStore.hasRole(Role.RoleNameEditDashboard)
  ) {
    containerProps.url = `/importDashboard/${item.menuItemId}`;
    endContent = <AtlasIcon type="Plus" />;
  }

  return (
    <OptionalTooltip
      enabled={!layoutStore.sidenavOpen || requiresEllipsis}
      text={item.displayName}
    >
      <MenuItemContainer {...containerProps}>
        {startContent}

        {variant === 'categoryHeader' && !layoutStore.sidenavOpen && (
          <MenuItemIcon iconType={item.icon} />
        )}

        {layoutStore.sidenavOpen && (
          <MenuItemText ref={ellipsisElem}>{item.displayName}</MenuItemText>
        )}

        {endContent}
      </MenuItemContainer>
    </OptionalTooltip>
  );
};

export default observer(MenuItem);
