import { emptyGuid } from 'components/Auth/utils';
import {
  IntAddPhoneNumberDto,
  IntAttachmentDto,
  IntConfirmEmailDto,
  IntConfirmEmailResult,
  IntEditUserProfileDto,
  IntForgotPasswordDto,
  IntGoogleAuthenticatorDto,
  IntGoogleAuthenticatorEnableDto,
  IntLoginFormDto,
  IntLoginResult,
  IntLoginResultDto,
  IntResetPasswordDto,
  IntResetPasswordResult,
  IntSendProviderCodeDto,
  IntUserDto,
  IntUserFeaturesRolesDto,
  IntVerifyCodeDto,
  IntVerifyPhoneNumberDto,
} from 'generated';
import { accountDemoData } from 'services/demoData/accountDemoData';
import {
  DeleteEndpoint,
  GetEndpoint,
  PatchEndpoint,
  PostEndpoint,
} from 'shared/interfaces/api';
import { IChangePassword } from 'shared/interfaces/change-password';

interface AccountAPI {
  forgotPassword: PostEndpoint<undefined, IntForgotPasswordDto>;
  changePassword: PostEndpoint<undefined, IChangePassword>;
  changeUserInfo: PostEndpoint<string | undefined, IntEditUserProfileDto>;
  deleteUserLogo: DeleteEndpoint<string>;
  emailVerification: PostEndpoint<
    undefined,
    IntConfirmEmailDto,
    IntConfirmEmailResult
  >;
  login: PostEndpoint<undefined, IntLoginFormDto, IntLoginResult>;
  logout: PostEndpoint;
  getUser: GetEndpoint<string | undefined, IntUserDto>;
  getUserAuthorization: GetEndpoint<undefined, IntUserFeaturesRolesDto>;
  get2faProviders: GetEndpoint<undefined, string[]>;
  sendCode: PostEndpoint<undefined, IntSendProviderCodeDto>;
  verifyCode: PostEndpoint<undefined, IntVerifyCodeDto, IntLoginResultDto>;
  uploadProfilePicture: PostEndpoint<string, FormData, IntAttachmentDto[]>;
  toggleTwoFactorAuthentication: PostEndpoint;
  getGoogleAuthenticatorDetails: GetEndpoint<
    undefined,
    IntGoogleAuthenticatorDto
  >;
  enableGoogleAuthenticator: PostEndpoint<
    undefined,
    IntGoogleAuthenticatorEnableDto
  >;
  disableGoogleAuthenticator: PostEndpoint;
  addPhoneNumber: PatchEndpoint<undefined, IntAddPhoneNumberDto>;
  verifyPhoneNumber: PostEndpoint<undefined, IntVerifyPhoneNumberDto>;
  deletePhoneNumber: DeleteEndpoint;
  resetPassword: PostEndpoint<
    undefined,
    IntResetPasswordDto,
    IntResetPasswordResult
  >;
}

const usersBase = '/api/users';
const accountBase = '/api/account';

export const accountService: AccountAPI = {
  forgotPassword: {
    url: `${accountBase}/ForgotPassword`,
    mockData: () => ({ status: 200 }),
  },
  changePassword: {
    url: `${accountBase}/ChangePassword`,
    mockData: () => ({ status: 200 }),
  },
  changeUserInfo: {
    url: (userId = emptyGuid) => `/api/users/${userId}`,
    mockData: accountDemoData.changeUserInfo,
  },
  deleteUserLogo: {
    url: userId => `${usersBase}/${userId}/DeleteProfilePicture`,
    mockData: () => ({ status: 200 }),
  },
  login: {
    url: `${accountBase}/Login`,
    mockData: accountDemoData.login,
  },
  logout: {
    url: `${accountBase}/Logout`,
    mockData: accountDemoData.logout,
  },
  getUser: {
    url: (userId = emptyGuid) => `/api/users/${userId}`,
    mockData: accountDemoData.getUser,
  },
  getUserAuthorization: {
    url: `${usersBase}/authorization`,
    mockData: accountDemoData.getUserAuthorization,
  },
  sendCode: {
    url: `${accountBase}/SendCode`,
    mockData: accountDemoData.sendCode,
  },
  verifyCode: {
    url: `${accountBase}/VerifyCode`,
    mockData: accountDemoData.verifyCode,
  },
  get2faProviders: {
    url: `${accountBase}/Providers`,
    mockData: accountDemoData.get2faProviders,
  },
  uploadProfilePicture: {
    url: customerId => `${usersBase}/${customerId}/UploadProfilePicture`,
    mockData: accountDemoData.uploadProfilePicture,
  },
  toggleTwoFactorAuthentication: {
    url: `${accountBase}/toggleTwoFactorAuthentication`,
    mockData: accountDemoData.toggleTwoFactorAuthentication,
  },
  getGoogleAuthenticatorDetails: {
    url: `${accountBase}/getGoogleAuthenticatorDetails`,
    mockData: accountDemoData.getGoogleAuthenticatorDetails,
  },
  enableGoogleAuthenticator: {
    url: `${accountBase}/enableGoogleAuthenticator`,
    mockData: accountDemoData.enableGoogleAuthenticator,
  },
  disableGoogleAuthenticator: {
    url: `${accountBase}/disableGoogleAuthenticator`,
    mockData: accountDemoData.disableGoogleAuthenticator,
  },
  addPhoneNumber: {
    url: `${accountBase}/phoneNumber`,
    mockData: accountDemoData.addPhoneNumber,
  },
  deletePhoneNumber: {
    url: `${accountBase}/phoneNumber`,
    mockData: accountDemoData.deletePhoneNumber,
  },
  verifyPhoneNumber: {
    url: `${accountBase}/verifyPhoneNumber`,
    mockData: () => ({ status: 200 }),
  },
  resetPassword: {
    url: `${accountBase}/resetPassword`,
    mockData: () => ({ status: 200, data: IntResetPasswordResult.Success }),
  },
  emailVerification: {
    url: `${accountBase}/confirmEmail`,
    mockData: () => ({ status: 200, data: IntConfirmEmailResult.Success }),
  },
};
