import { Box, Button, Grid, Portal, Tab, Tabs } from '@mui/material';
import { Role } from 'components/Auth/Role';
import { FormField } from 'components/FormFields';
import LoadingOverlay from 'components/LoadingOverlay';
import TabPanel from 'components/TabPanel';
import { Form, Formik } from 'formik';
import {
  IntAtlasServicePropertyDto,
  IntPolicyDto,
  ServicePropertyDataType,
} from 'generated';
import i18n from 'i18n';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'store';
import { Yup } from 'utils';
import AddPropertyStatusButton from './AddPropertyStatusButton';
import ConfirmDeletePolicy from './ConfirmDeletePolicy';
import DataStatusForm from './DataStatusForm';
import LoadServiceDetails from './LoadServiceDetails';
import PolicyCustomerField from './PolicyCustomerField';
import SortedStatuses from './SortedStatuses';
import StatusTabLabel from './StatusTabLabel';

const statusSchema = (serviceProperties: IntAtlasServicePropertyDto[]) => {
  return Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string(),
    customerId: Yup.string().nullable(),
    dealerId: Yup.string().nullable(),
    servicePropertyId: Yup.string().nullable(),
    rules: Yup.array().when('servicePropertyId', {
      is: (servicePropertyId: string) => {
        return (
          serviceProperties.find(
            sp => sp.servicePropertyId === servicePropertyId
          )?.dataType === ServicePropertyDataType.Text
        );
      },
      then: Yup.array().of(
        Yup.object({
          color: Yup.string().required(),
          icon: Yup.string().nullable(),
          id: Yup.string().required(),
          label: Yup.string().nullable(),
          description: Yup.string().nullable(),
          min: Yup.number().nullable(),
          max: Yup.number().nullable(),
          textValue: Yup.string().required(),
          textComparer: Yup.number().required(),
        })
      ),
      otherwise: Yup.array().of(
        Yup.object({
          color: Yup.string().required(),
          icon: Yup.string().nullable(),
          id: Yup.string().required(),
          label: Yup.string().nullable(),
          description: Yup.string().nullable(),
          textValue: Yup.string().nullable(),
          textComparer: Yup.number().nullable(),
          min: Yup.number()
            .nullable()
            .test(
              'minOrMaxRequired',
              i18n.t('policy:error.min_or_max_required'),
              function (item) {
                return item != null || this.parent.max != null;
              }
            )
            .test(
              'minSmallerThanMax',
              i18n.t('validation:invalid.max_value', { maxValue: 'max' }),
              function (item) {
                return (
                  item == null ||
                  this.parent.max == null ||
                  item <= this.parent.max
                );
              }
            ),
          max: Yup.number()
            .nullable()
            .test(
              'minOrMaxRequired',
              i18n.t('policy:error.min_or_max_required'),
              function (item) {
                return this.parent.min != null || item != null;
              }
            )
            .test(
              'minSmallerThanMax',
              i18n.t('validation:invalid.min_value', { minValue: 'min' }),
              function (item) {
                return (
                  item == null ||
                  this.parent.min == null ||
                  item >= this.parent.min
                );
              }
            ),
        })
      ),
    }),
  });
};

const policyValidationSchema = (
  serviceProperties: IntAtlasServicePropertyDto[]
) =>
  Yup.form<IntPolicyDto>({
    policyId: Yup.string().required(),
    name: Yup.string().required(),
    customerId: Yup.string(),
    statuses: Yup.array().of(statusSchema(serviceProperties)),
  });

interface IProps {
  policy: IntPolicyDto;
  topRef?: RefObject<HTMLDivElement> | null;
}

const PolicyForm: React.FC<IProps> = ({ policy, topRef }) => {
  const {
    authStore: { hasRole },
    policyStore: { updatePolicy },
    dashboardStore: { serviceProperties },
  } = useStores();
  const { t } = useTranslation('policy');
  const initialValues = toJS(policy);
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (_e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [showConfirmDeletePolicy, setShowConfirmDeletePolicy] = useState(false);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={updatePolicy}
        enableReinitialize
        validationSchema={policyValidationSchema(serviceProperties)}
      >
        {({ values, dirty, isSubmitting, errors }) => (
          <Form id="editPolicyForm">
            <LoadServiceDetails>
              <LoadingOverlay isLoading={isSubmitting}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <FormField.TextField name="name" label={t('name')} />
                  </Grid>
                  <Grid item sm={6}>
                    <PolicyCustomerField />
                  </Grid>
                  <Grid item sm={10}>
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      variant="scrollable"
                    >
                      {values.statuses?.map((status, i) => (
                        <Tab
                          key={status.id}
                          label={
                            <StatusTabLabel
                              isActive={tabValue === i}
                              status={status}
                              isError={!!errors.statuses?.[i]}
                              setTabIndex={setTabValue}
                            />
                          }
                        />
                      ))}
                    </Tabs>
                  </Grid>
                  <Grid item sm={2} display="flex" justifyContent="flex-end">
                    <AddPropertyStatusButton setTabIndex={setTabValue} />
                  </Grid>
                  <Grid item sm={12}>
                    {values.statuses?.map((status, i) => (
                      <TabPanel value={tabValue} index={i} key={status.id}>
                        <SortedStatuses
                          statusRules={status.rules}
                          isStringProperty={
                            serviceProperties.find(
                              sp =>
                                sp.servicePropertyId ===
                                status.servicePropertyId
                            )?.dataType === ServicePropertyDataType.Text
                          }
                        />

                        <DataStatusForm status={status} index={i} />
                      </TabPanel>
                    ))}
                  </Grid>
                </Grid>
                <Portal container={topRef?.current}>
                  <Box py={2} display="flex" justifyContent="space-between">
                    {hasRole(Role.RoleNameDeleteDashboardDataStatus) && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setShowConfirmDeletePolicy(true)}
                      >
                        {t('action.delete_policy')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      color="primary"
                      style={{ marginLeft: 5 }}
                      variant="contained"
                      form="editPolicyForm"
                      disabled={!dirty}
                    >
                      {t('action.save_policy')}
                    </Button>
                  </Box>
                </Portal>
              </LoadingOverlay>
            </LoadServiceDetails>
          </Form>
        )}
      </Formik>
      <ConfirmDeletePolicy
        isOpen={showConfirmDeletePolicy}
        close={() => setShowConfirmDeletePolicy(false)}
        policy={policy}
      />
    </>
  );
};

export default observer(PolicyForm);
