import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import AtlasIcon from 'components/AtlasIcon';
import { Role } from 'components/Auth/Role';
import { FormField } from 'components/FormFields';
import { useFormikContext } from 'formik';
import {
  IntDashboardDataStatusRuleDto,
  IntPolicyDto,
  IntTriggerConditionType,
  ServicePropertyDataType,
} from 'generated';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TeliaIcons from 'shared/icons/telia-icons';
import { AtlasIconType } from 'shared/types/atlas-icon';
import { useStores } from 'store';
import { rnd } from 'utils';
import { triggerConditionTypes } from 'views/Management/Automations/EditAutomationWizard/enums';
import { useStyles } from './RulesForm.styles';
import useFormatStringValue from './useFormatStringValue';
import useFormatRangeValue from './useValueRangeFormat';

interface IProps {
  statusColor: 'green' | 'yellow' | 'red';
  namePrefix: string;
  rules: IntDashboardDataStatusRuleDto[];
  title: string;
  unit: string;
  defaultIcon: AtlasIconType;
  addStatusLabel: string;
  propertyType?: ServicePropertyDataType;
}

const iconOptions = Object.keys(TeliaIcons)
  .sort()
  .map(icon => ({ iconType: icon as AtlasIconType, label: icon }));

const RulesFrom: React.FC<IProps> = ({
  statusColor,
  rules,
  namePrefix,
  title,
  unit,
  defaultIcon,
  addStatusLabel,
  propertyType,
}) => {
  const {
    authStore: { hasRole },
  } = useStores();
  const classes = useStyles();

  const { setFieldValue } = useFormikContext<IntPolicyDto>();
  const { t } = useTranslation('policy');

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const filteredArray = rules
    .map((rule, index) => ({ rule, index }))
    .filter(r => r.rule.color === statusColor);

  const addRule = (
    oldRules: IntDashboardDataStatusRuleDto[],
    variant: string | null
  ): IntDashboardDataStatusRuleDto[] => {
    const newRule = {
      id: rnd.guid(),
      color: variant,
      label: '',
      min: null,
      max: null,
      icon: '',
      description: '',
      textValue: '',
    };
    setExpanded(newRule.id);
    return [...oldRules, newRule];
  };

  const iconColor = (color: string | null) => {
    if (color === 'red') {
      return 'error';
    } else if (color === 'yellow') {
      return 'warning';
    } else if (color === 'green') {
      return 'success';
    } else {
      return 'disabled';
    }
  };

  const formatRangeValue = useFormatRangeValue();

  const formatStringValue = useFormatStringValue();

  return (
    <Box mb={5}>
      <Box py={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>
        {hasRole(Role.RoleNameEditDashboardDataStatus) && (
          <Button
            variant={rules.length === 0 ? 'contained' : 'text'}
            color="primary"
            size="small"
            onClick={() => {
              setFieldValue(`${namePrefix}.rules`, addRule(rules, statusColor));
            }}
          >
            {addStatusLabel}
            <AtlasIcon type="Plus" size={16} />
          </Button>
        )}
      </Box>
      {filteredArray.map(rule => (
        <Grid key={rule.rule.id} item sm={12}>
          <Accordion
            className={classes.accordion}
            expanded={expanded === rule.rule.id}
            onChange={handleChange(rule.rule.id)}
          >
            <AccordionSummary
              expandIcon={<AtlasIcon type="ChevronDown" size={24} />}
              className={classes.accordionSummary}
            >
              <Grid container item sm={12} alignItems="center">
                <Grid item sm={4} display="flex" alignItems="center">
                  <AtlasIcon
                    type={
                      rule.rule.icon
                        ? (rule.rule.icon as AtlasIconType)
                        : defaultIcon
                    }
                    size={40}
                    color={iconColor(rules[rule.index].color)}
                  />

                  <Typography paddingLeft={2} variant="h6">
                    {propertyType === ServicePropertyDataType.Number
                      ? formatRangeValue(rule.rule.min, rule.rule.max)
                      : formatStringValue(
                          rule.rule.textComparer,
                          rule.rule.textValue
                        )}
                    <span className="text-unit">{unit}</span>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="h6">
                    {rules[rule.index].label}
                  </Typography>
                  <Typography>{rules[rule.index].description}</Typography>
                </Grid>
                <Grid item sm={2} display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() =>
                      setFieldValue(
                        `${namePrefix}.rules`,
                        rules.filter((_, i) => i !== rule.index)
                      )
                    }
                  >
                    <AtlasIcon type="TrashEmpty" />
                  </Button>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box mb={3} display="flex" position="relative">
                    {propertyType === ServicePropertyDataType.Number ? (
                      <>
                        <Grid item sm={4}>
                          <FormField.NumberField
                            name={`${namePrefix}.rules[${rule.index}].min`}
                            label={t('min')}
                          />
                        </Grid>
                        <Typography
                          style={{
                            margin: '15px 10px',
                          }}
                          variant="h6"
                        >
                          -
                        </Typography>
                        <Grid item sm={4}>
                          <FormField.NumberField
                            name={`${namePrefix}.rules[${rule.index}].max`}
                            label={t('max')}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item sm={4} mr={4}>
                          <FormField.Autocomplete
                            name={`${namePrefix}.rules[${rule.index}].textComparer`}
                            label={t(
                              'automation:dialog_create_automation.label.trigger.condition_type'
                            )}
                            options={triggerConditionTypes.filter(
                              type =>
                                type.value ===
                                  IntTriggerConditionType.EqualTo ||
                                type.value ===
                                  IntTriggerConditionType.NotEqualTo
                            )}
                            getOptionLabel={opt => opt.label}
                            getOptionValue={opt => opt.value}
                            required={
                              propertyType === ServicePropertyDataType.Text
                            }
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <FormField.TextField
                            name={`${namePrefix}.rules[${rule.index}].textValue`}
                            label={t('text_value')}
                            required={
                              propertyType === ServicePropertyDataType.Text
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Box>
                </Grid>

                <Grid item sm={6}>
                  <Grid mb={3}>
                    <FormField.TextField
                      name={`${namePrefix}.rules[${rule.index}].label`}
                      label={t('label')}
                      maxLength={50}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={6}>
                  <FormField.Autocomplete
                    name={`${namePrefix}.rules[${rule.index}].icon`}
                    label={t('icon')}
                    options={iconOptions}
                    getOptionLabel={opt => opt.label}
                    getOptionValue={opt => opt.iconType}
                    getOptionIcon={opt => opt.iconType}
                    clearable
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormField.TextField
                    rows={4}
                    multiline
                    name={`${namePrefix}.rules[${rule.index}].description`}
                    label={t('description')}
                    maxLength={200}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
      {filteredArray.length === 0 && t('no_status')}
    </Box>
  );
};

export default RulesFrom;
