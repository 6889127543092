import { Option } from 'components/FormFields';
import {
  AutomationRuleActionWebHookAuthType,
  AutomationRuleActionWebHookMethod,
  AutomationRuleActionWebHookPayloadType,
  IntActionReasonValueType,
  IntAutomationActionConnectivityUnitEventType,
  IntAutomationActionType,
  IntAutomationRuleFilterType,
  IntAutomationRuleType,
  IntAutomationTriggerType,
  IntConnectivityUnitStatusValueType,
  IntEntityPropertyName,
  IntFilterEntityType,
  IntPropertyUnitType,
  IntTriggerConditionOptionType,
  IntTriggerConditionTimeInterval,
  IntTriggerConditionType,
  IntTriggerValueControlType,
  TriggerTerminalAttribute,
} from 'generated';
import { mapEnum } from 'utils';

export const ruleTypes: Option<IntAutomationRuleType>[] = mapEnum(
  IntAutomationRuleType,
  'automation:rule_types'
);

export const filterEntityTypes: Option<IntFilterEntityType>[] = mapEnum(
  IntFilterEntityType,
  'automation:filter_entity_types'
);

export const automationRuleFilterTypes: Option<IntAutomationRuleFilterType>[] =
  mapEnum(
    IntAutomationRuleFilterType,
    'automation:automation_rule_filter_types'
  );

export const triggerTypes: Option<IntAutomationTriggerType>[] = mapEnum(
  IntAutomationTriggerType,
  'automation:trigger_types'
);

export const entityProperties: Option<IntEntityPropertyName>[] = mapEnum(
  IntEntityPropertyName,
  'automation:entity_properties'
);

export const propertyUnitTypes: Option<IntPropertyUnitType>[] = mapEnum(
  IntPropertyUnitType,
  'automation:propertyunit_types',
  true
);

export const triggerConditionTypes: Option<IntTriggerConditionType>[] = mapEnum(
  IntTriggerConditionType,
  'automation:trigger_condition_types'
);

export const triggerTimeIntervals: Option<IntTriggerConditionTimeInterval>[] =
  mapEnum(IntTriggerConditionTimeInterval, 'automation:time_intervals');

export const actionTypes: Option<IntAutomationActionType>[] = mapEnum(
  IntAutomationActionType,
  'automation:action.types'
);

export const simActionTypes: Option<IntAutomationActionConnectivityUnitEventType>[] =
  mapEnum(
    IntAutomationActionConnectivityUnitEventType,
    'automation:action.sim_events'
  );

export const connectivityUnitPropertyUnit: Option<IntConnectivityUnitStatusValueType>[] =
  mapEnum(IntConnectivityUnitStatusValueType, 'automation:trigger_event_types');

export const testmodePropertyUnit: Option<IntActionReasonValueType>[] = mapEnum(
  IntActionReasonValueType,
  'automation:trigger_testmode_types'
);
export const triggerControlProperty: Option<IntTriggerValueControlType>[] =
  mapEnum(
    IntTriggerValueControlType,
    'automation:trigger_control_property_type'
  );

export const triggerOptionTypes: Option<IntTriggerConditionOptionType>[] =
  mapEnum(
    IntTriggerConditionOptionType,
    'automation:trigger_condition_options'
  );

export const timeUnits: Option<IntTriggerConditionTimeInterval>[] = mapEnum(
  IntTriggerConditionTimeInterval,
  'automation:time_intervals'
);

export const terminalAttributes: Option<TriggerTerminalAttribute>[] = mapEnum(
  TriggerTerminalAttribute,
  'automation:terminal_attributes'
);

export const webhookPayloadTypes: Option<AutomationRuleActionWebHookPayloadType>[] =
  mapEnum(
    AutomationRuleActionWebHookPayloadType,
    'automation:webhook_payload_types'
  );

export const webhookAuthTypes: Option<AutomationRuleActionWebHookAuthType>[] =
  mapEnum(AutomationRuleActionWebHookAuthType, 'automation:webhook_auth_types');

export const webhookHttpMethodTypes: Option<AutomationRuleActionWebHookMethod>[] =
  mapEnum(
    AutomationRuleActionWebHookMethod,
    'automation:webhook_http_method_types'
  );
