import { lazy } from 'react';

const Login = lazy(
  () => import(/* webpackChunkName: "Login" */ './Account/Login')
);
const ForgotPassword = lazy(
  () =>
    import(/* webpackChunkName: "ForgotPassword" */ './Account/ForgotPassword')
);
const Home = lazy(() => import(/* webpackChunkName: "Home" */ './Home'));
const Profile = lazy(
  () => import(/* webpackChunkName: "Profile" */ './Profile')
);
const EnterpriseProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "EnterpriseProfile" */ './Management/Enterprises/Profile'
    )
);
const Users = lazy(
  () => import(/* webpackChunkName: "Users" */ './Management/Users')
);
const Services = lazy(
  () => import(/* webpackChunkName: "Services" */ './Management/Services')
);
const ServiceDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ServiceDetails" */ './Management/Services/Details'
    )
);
const Sim = lazy(
  () => import(/* webpackChunkName: "Sim" */ './Connectivity/Sim')
);
const Reporting = lazy(
  () => import(/* webpackChunkName: "Reporting" */ './Connectivity/Reporting')
);
const SimDetails = lazy(
  () =>
    import(/* webpackChunkName: "SimDetails" */ './Connectivity/Sim/Details')
);
const Orders = lazy(
  () => import(/* webpackChunkName: "Orders" */ './Connectivity/Orders')
);
const Assets = lazy(() => import(/* webpackChunkName: "Assets" */ './Assets'));
const AssetsTableLight = lazy(
  () => import(/* webpackChunkName: "AssetsLight" */ './AssetsLight')
);
const AssetDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "AssetDetails" */ './Assets/Details/AssetDetails'
    )
);
const Devices = lazy(
  () => import(/* webpackChunkName: "Devices" */ './ManagedGateway/Devices')
);
const DeviceDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "DeviceDetails" */ './ManagedGateway/DeviceDetails'
    )
);
const Enterprises = lazy(
  () => import(/* webpackChunkName: "Enterprises" */ './Management/Enterprises')
);
const ServiceRequest = lazy(
  () => import(/* webpackChunkName: "ServiceRequest" */ './ServiceRequest')
);
const Reports = lazy(
  () => import(/* webpackChunkName: "Reports" */ './Reports')
);
const ApnHub = lazy(
  () => import(/* webpackChunkName: "ApnHub" */ './Connectivity/ApnHub')
);
const ApnHubDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ApnHubDetails" */ './Connectivity/ApnHub/ProfileDetails'
    )
);
const ApnHubActiveSessions = lazy(
  () =>
    import(
      /* webpackChunkName: "ApnHubActiveSessions" */ './Connectivity/ApnHub/ActiveSessions'
    )
);
const ApnHubHistoricalSessions = lazy(
  () =>
    import(
      /* webpackChunkName: "ApnHubHistoricalSessions" */ './Connectivity/ApnHub/HistoricalSessions'
    )
);
const ApnHubFailedSessions = lazy(
  () =>
    import(
      /* webpackChunkName: "ApnHubFailedSessions" */ './Connectivity/ApnHub/FailedSessions'
    )
);
const SmsHub = lazy(
  () => import(/* webpackChunkName: "SmsHub" */ './Connectivity/SmsHub')
);
const Groups = lazy(
  () => import(/* webpackChunkName: "Groups" */ './Connectivity/Groups')
);
const GroupDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "GroupDetails" */ './Connectivity/Groups/Details'
    )
);
const Statistics = lazy(
  () => import(/* webpackChunkName: "Statistics" */ './Statistics')
);
const Invoices = lazy(
  () => import(/* webpackChunkName: "Invoices" */ './Management/Invoices')
);
const Automations = lazy(
  () => import(/* webpackChunkName: "Automations" */ './Management/Automations')
);
const ErrorPage = lazy(
  () => import(/* webpackChunkName: "ErrorPage" */ './404Page')
);
const UnderConstruction = lazy(
  () =>
    import(/* webpackChunkName: "UnderConstruction" */ './UnderConstruction')
);
const ThemePreview = lazy(
  () => import(/* webpackChunkName: "ThemePreview" */ './ThemePreview')
);

const ScanStart = lazy(
  () => import(/* webpackChunkName: "ScanStart" */ './ScanAsset/Start')
);

const ScanAdd = lazy(
  () =>
    import(
      /* webpackChunkName: "ScanAdd" */ './ScanAsset/ScanAdd/QRScannerView'
    )
);

const ScanDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ScanDetails" */ './ScanAsset/ScanAdd/DetailsView'
    )
);

const ScanList = lazy(
  () => import(/* webpackChunkName: "ScanList" */ './ScanAsset/ScanList')
);

const Dashboard = lazy(
  () => import(/* webpackChunkName: "Dashboard" */ './Dashboard')
);
const TempImportDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "TempImportDashboard" */ './Dashboard/TempImportDashboard'
    )
);

const Connectivity = lazy(
  () => import(/* webpackChunkName: "ConnectivityDashboard" */ './Connectivity')
);

const AutomationDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "AutomationDetails" */ './Management/Automations/AutomationDetails'
    )
);

const Admin = lazy(() => import(/* webpackChunkName: "Admin" */ './Admin'));

const RealEstateItAssets = lazy(
  () =>
    import(/* webpackChunkName: "RealEstateItAssets" */ './RealEstateIt/Assets')
);

const RealEstateItAssetDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "RealEstateItAssetDetails" */ './RealEstateIt/Assets/Details'
    )
);

const RealEstateItProfiles = lazy(
  () =>
    import(
      /* webpackChunkName: "RealEstateItProfiles" */ './RealEstateIt/Profiles'
    )
);

const RealEstateItProfilesDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "RealEstateItProfilesDetails" */ './RealEstateIt/Profiles/Details'
    )
);

const RealEstateItLocations = lazy(
  () =>
    import(
      /* webpackChunkName: "RealEstateItLocations" */ './RealEstateIt/Locations'
    )
);

const RealEstateItLocationsDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "RealEstateItLocationsDetails" */ './RealEstateIt/Locations/Details'
    )
);

const RealEstateItSuppliers = lazy(
  () =>
    import(
      /* webpackChunkName: "RealEstateItSuppliers" */ './RealEstateIt/Suppliers'
    )
);

const RealEstateItSuppliersDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "RealEstateItSuppliersDetails" */ './RealEstateIt/Suppliers/Details'
    )
);

const Support = lazy(
  () => import(/* webpackChunkName: "Support" */ './Support')
);

const SupportForm = lazy(
  () => import(/* webpackChunkName: "SupportForm" */ './Support/SupportForm')
);

const TicketDetails = lazy(
  () => import(/* webpackChunkName: "TicketDetails" */ './Support/Details/')
);

const SwapDevice = lazy(
  () => import(/* webpackChunkName: "SwapDevice" */ './ScanAsset/SwapDevice')
);

const SwapScanView = lazy(
  () =>
    import(
      /* webpackChunkName: "SwapScanView" */ './ScanAsset/SwapDevice/SwapScanView'
    )
);

const ConfirmSwap = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmSwap" */ './ScanAsset/SwapDevice/SwapConfirm'
    )
);

const UserProfile = lazy(
  () =>
    import(/* webpackChunkName: "UserProfile" */ './Management/Users/Profile')
);

const ManageNavigation = lazy(
  () => import(/* webpackChunkName: "ManageNavigation" */ './Navigation/Manage')
);

const ManageUiConfiguration = lazy(
  () =>
    import(
      /* webpackChunkName: "ManageUiConfig" */ './UiConfiguration/ManageUiConfig'
    )
);

const ResetPassword = lazy(
  () =>
    import(/* webpackChunkName: "ResetPassword" */ './Account/ResetPassword')
);
const EmailVerification = lazy(
  () =>
    import(
      /* webpackChunkName: "EmailVerification" */ './Account/EmailVerification'
    )
);
const RolesAndPermissions = lazy(
  () =>
    import(
      /* webpackChunkName: "RolesAndPermissions" */ './Management/RolesAndPermissions'
    )
);

const Extensions = lazy(
  () => import(/* webpackChunkName: "Extensions" */ './Management/Extensions')
);

const ExtensionDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ExtensionDetails" */ './Management/Extensions/Details'
    )
);

const WorkloadDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "WorkloadDetails" */ './Management/Extensions/Workloads/Details'
    )
);

const ProductTemplateManage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProductManage" */ './Navigation/ProductTemplate'
    )
);

const Audit = lazy(() => import(/* webpackChunkName: "Audit" */ './Audit'));
const Feature = lazy(
  () => import(/* webpackChunkName: "Feature" */ './Admin/FeatureManagement')
);

const Dealers = lazy(
  () => import(/* webpackChunkName: "Dealers" */ './Management/Dealers')
);

const DealerDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "DealerDetails" */ './Management/Dealers/Details'
    )
);

const VpnServer = lazy(
  () => import(/* webpackChunkName: "VpnServer" */ './Management/VpnServer')
);

const VpnServerDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "VpnServerDetails" */ './Management/VpnServer/Details'
    )
);

const SubPortals = lazy(
  () => import(/* webpackChunkName: "SubPortals" */ './Management/SubPortals')
);

const IntelligenceEvents = lazy(
  () =>
    import(
      /* webpackChunkName: "IntelligenceEvents" */ './Admin/IntelligenceEvents'
    )
);

export {
  AutomationDetails,
  Login,
  ForgotPassword,
  Home,
  Profile,
  EnterpriseProfile,
  Users,
  Services,
  ServiceDetails,
  SimDetails,
  Reporting,
  Sim,
  Assets,
  AssetsTableLight,
  AssetDetails,
  Devices,
  DeviceDetails,
  Dealers,
  DealerDetails,
  VpnServer,
  VpnServerDetails,
  Enterprises,
  Orders,
  ServiceRequest,
  Reports,
  ApnHub,
  ApnHubDetails,
  ApnHubActiveSessions,
  ApnHubHistoricalSessions,
  ApnHubFailedSessions,
  SmsHub,
  Groups,
  GroupDetails,
  Statistics,
  Invoices,
  Automations,
  ErrorPage,
  UnderConstruction,
  TicketDetails,
  ThemePreview,
  ScanStart,
  ScanAdd,
  ScanDetails,
  ScanList,
  SwapDevice,
  ConfirmSwap,
  SwapScanView,
  Dashboard,
  TempImportDashboard,
  Connectivity,
  RealEstateItAssets,
  RealEstateItAssetDetails,
  RealEstateItProfiles,
  RealEstateItProfilesDetails,
  RealEstateItLocations,
  RealEstateItLocationsDetails,
  RealEstateItSuppliers,
  RealEstateItSuppliersDetails,
  Admin,
  Support,
  SupportForm,
  UserProfile,
  ManageNavigation,
  ResetPassword,
  Extensions,
  ExtensionDetails,
  EmailVerification,
  ManageUiConfiguration,
  WorkloadDetails,
  RolesAndPermissions,
  ProductTemplateManage,
  Audit,
  Feature,
  SubPortals,
  IntelligenceEvents,
};
