import { EFeature } from 'components/Auth/EFeature';
import { Role } from 'components/Auth/Role';
import { AtlasLayoutType } from 'shared/types/atlas-layout-type';
import * as views from 'views';
import { IRouteItem } from './shared/interfaces/route-item';

const routes: IRouteItem[] = [
  {
    text: 'my-dashboard',
    path: '/',
    icon: 'Home',
    exact: true,
    component: <views.Home />,
    // roles todo? Some start page will always be required - might switch content in the view?
  },
  {
    text: 'connectivity',
    path: '/connectivity',
    exact: true,
    component: <views.Connectivity />,
    // roles todo? Some start page will always be required - might switch content in the view?
  },
  {
    text: 'admin',
    path: '/admin',
    exact: true,
    roles: Role.ViewAdminPages,
    component: <views.Admin />,
  },
  {
    text: 'login',
    path: '/auth/login',
    component: <views.Login />,
    layout: 'auth',
  },
  {
    text: 'forgot-password',
    path: '/auth/forgot-password',
    component: <views.ForgotPassword />,
    layout: 'auth',
  },
  {
    text: 'reset-password',
    path: '/auth/reset-password',
    component: <views.ResetPassword />,
    layout: 'auth',
  },
  {
    text: 'email-verification',
    path: '/auth/email-verification',
    component: <views.EmailVerification />,
    layout: 'auth',
  },
  {
    text: 'my-profile',
    path: '/profile',
    component: <views.Profile />,
    roles: Role.RoleNameViewUser,
  },
  {
    text: 'theme',
    path: '/theme',
    component: <views.ThemePreview />,
    feature: EFeature.EnablePreviewFeatures,
    exact: true,
  },
  {
    text: 'service-request',
    path: '/orders/service-request',
    layout: 'public',
    component: <views.ServiceRequest />,
  },
  // Temporary POC route for all dashboards. Todo: take a final decision on how to organize dashboards and make this more dynamic.
  {
    text: 'bcm-dashboard',
    path: '/building-climate-monitoring/:dashboardId',
    component: <views.Dashboard />,
    feature: EFeature.EnableDashboards,
    roles: Role.RoleNameViewDashboard,
    showInMenu: false, // This is for rendering <Route> only, menu items are computed in DashboardStore
    breadcrumbOptions: {
      hasCustomLabel: true,
    },
  },
  {
    text: 'dashboard',
    path: '/dashboard/:dashboardId',
    component: <views.Dashboard />,
    feature: EFeature.EnableDashboards,
    roles: Role.RoleNameViewDashboard,
    showInMenu: false,
    breadcrumbOptions: {
      hasCustomLabel: true,
    },
  },
  {
    text: 'dashboard',
    path: '/importDashboard/:menuItemId',
    component: <views.TempImportDashboard />,
    feature: EFeature.EnableDashboards,
    roles: Role.RoleNameViewDashboard,
    showInMenu: false,
  },
  {
    text: 'connectivity',
    path: '/connectivity',
    component: <views.Connectivity />,
    icon: 'Router',
    showInMenu: true,
    topLevel: true,
    subLinks: [
      {
        text: 'sim',
        path: '/sim',
        icon: 'Simcard',
        exact: true,
        roles: Role.RoleNameViewConnectivityUnit,
        feature: EFeature.ConnectivityEnableSimInventory,
        component: <views.Sim />,
        showInMenu: true,
        subLinks: [
          {
            text: 'sim-details',
            path: '/:connectivityUnitId',
            component: <views.SimDetails />,
            roles: Role.RoleNameViewConnectivityUnit,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'orders',
        path: '/orders',
        icon: 'Shopping',
        component: <views.Orders />,
        roles: Role.RoleNameViewConnectivityOrder,
        feature: EFeature.ConnectivityEnableSimOrders,
        showInMenu: true,
      },
      {
        text: 'reports',
        path: '/reports',
        exact: true,
        component: <views.Reporting />,
        feature: EFeature.ConnectivityEnableReports,
        roles: Role.RoleNameViewReport,
        showInMenu: true,
        icon: 'Report',
      },
      {
        text: 'groups',
        path: '/groups',
        icon: 'People',
        component: <views.Groups />,
        exact: true,
        roles: Role.RoleNameViewConnectivityGroup,
        feature: EFeature.ConnectivityEnableSimGroups,
        showInMenu: true,
        subLinks: [
          {
            text: 'group-details',
            path: '/:groupId',
            component: <views.GroupDetails />,
            roles: Role.RoleNameViewConnectivityGroup,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'apn-hub',
        path: '/apn-hub',
        icon: 'Secured',
        exact: true,
        component: <views.ApnHub />,
        roles: Role.RoleNameViewIpAccess,
        feature: EFeature.ConnectivityEnableIpSettings,
        showInMenu: true,
        subLinks: [
          {
            text: 'historical-data-sessions',
            path: '/historical-data-sessions',
            icon: 'Historical',
            exact: true,
            showInMenu: true,
            component: <views.ApnHubHistoricalSessions />,
            roles: Role.RoleNameViewIpAccess,
            breadcrumbOptions: {
              hasCustomLabel: false,
            },
          },
          {
            text: 'active-data-sessions',
            path: '/active-data-sessions',
            icon: 'Run',
            exact: true,
            showInMenu: true,
            component: <views.ApnHubActiveSessions />,
            roles: Role.RoleNameViewIpAccess,
            breadcrumbOptions: {
              hasCustomLabel: false,
            },
          },
          {
            path: '/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
            component: <views.ApnHubDetails />,
            roles: Role.RoleNameViewIpAccess,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'sms-hub',
        path: '/sms-hub',
        icon: 'SmsHub',
        component: <views.SmsHub />,
        exact: true,
        roles: Role.RoleNameViewSmsHub,
        showInMenu: true,
      },
    ],
  },
  {
    text: 'managed-gateway',
    path: '/managed-gateway',
    icon: 'WifiMobile',
    showInMenu: true,
    topLevel: true,
    exact: true,
    subLinks: [
      {
        text: 'devices',
        path: '/devices',
        icon: 'Devices',
        roles: Role.RoleNameViewTerminal,
        component: <views.Devices />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'device-details',
            path: '/:terminalId',
            component: <views.DeviceDetails />,
            roles: Role.RoleNameViewTerminal,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
    ],
  },
  {
    text: 'management',
    path: '/management',
    roles: Role.AccessManagementPortal, // Is this actually correct?
    icon: 'Repair',
    showInMenu: true,
    // Currently the EnableManagement feature is used to toggle if the user can access the old Management Portal
    // The links in this category should still be visible (depending on roles)
    // feature: EFeature.EnableManagement,
    topLevel: true,
    subLinks: [
      {
        text: 'enterprises',
        path: '/enterprises',
        icon: 'Network',
        roles: Role.RoleNameViewCustomer,
        component: <views.Enterprises />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'enterprise-details',
            path: '/:customerId',
            component: <views.EnterpriseProfile />,
            roles: Role.RoleNameViewCustomer,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'users',
        path: '/users',
        icon: 'People',
        roles: Role.RoleNameViewUser,
        component: <views.Users />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'user-details',
            path: '/:userId',
            component: <views.UserProfile />,
            roles: Role.RoleNameViewUser,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'roles-and-permissions',
        path: '/roles-and-permissions',
        icon: 'UserAdmin',
        roles: Role.RoleNameViewGroup,
        component: <views.RolesAndPermissions />,
        showInMenu: true,
      },
      {
        // Candidate for removal
        text: 'products',
        path: '/products',
        icon: 'Box',
        roles: Role.RoleNameViewProduct,
        feature: EFeature.EnablePreviewFeatures,
        component: <views.UnderConstruction />,
        showInMenu: true,
      },
      {
        text: 'invoices',
        path: '/invoices',
        icon: 'Wallet',
        component: <views.Invoices />,
        roles: Role.RoleNameViewInvoice,
        showInMenu: true,
      },
      {
        text: 'automations',
        path: '/automations',
        icon: 'BusinessContinuity',
        roles: Role.RoleNameViewAutomationRule,
        component: <views.Automations />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'automation-details',
            path: '/:automationId',
            roles: Role.RoleNameViewAutomationRule,
            component: <views.AutomationDetails />,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'assets',
        path: '/assets',
        icon: 'Devices',
        roles: Role.RoleNameViewAsset,
        component: <views.Assets />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'asset-details',
            path: '/:assetId',
            roles: Role.RoleNameViewAsset,
            component: <views.AssetDetails />,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'extensions',
        path: '/extensions',
        icon: 'List',
        roles: Role.RoleNameViewExtension,
        component: <views.Extensions />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'extension-details',
            path: '/:extensionId',
            component: <views.ExtensionDetails />,
            roles: Role.RoleNameViewExtension,
            exact: true,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
            subLinks: [
              {
                text: 'workload-details',
                path: '/:workloadId',
                component: <views.WorkloadDetails />,
                roles: Role.RoleNameViewExtension,
                exact: true,
                breadcrumbOptions: {
                  hasCustomLabel: true,
                },
              },
            ],
          },
        ],
      },
      {
        text: 'reports',
        path: '/reports',
        icon: 'Statistics',
        roles: Role.RoleNameViewReportTemplate,
        component: <views.Reports />,
        exact: true,
        showInMenu: true,
      },
      {
        text: 'services',
        path: '/services',
        icon: 'Statistics',
        roles: Role.RoleNameViewService,
        component: <views.Services />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'service-details',
            path: '/:serviceId',
            component: <views.ServiceDetails />,
            roles: Role.RoleNameViewService,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'dealers',
        path: '/dealers',
        icon: 'Premises',
        roles: Role.RoleNameViewDealer,
        component: <views.Dealers />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'dealer-details',
            path: '/:dealerId',
            component: <views.DealerDetails />,
            roles: Role.RoleNameViewDealer,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'vpn-server',
        path: '/vpn-server',
        icon: 'VpnServer',
        roles: Role.RoleNameViewVpnServer,
        component: <views.VpnServer />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'vpn-server-details',
            path: '/:vpnServerId',
            component: <views.VpnServerDetails />,
            roles: Role.RoleNameViewVpnServer,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
    ],
  },
  {
    text: 'smart-buildings-table',
    path: '/assets-table',
    roles: Role.RoleNameViewAsset,
    icon: 'Devices',
    topLevel: true,
    showInMenu: true,
    exact: true,
    component: <views.AssetsTableLight />,
  },
  {
    text: 'admin',
    path: '/admin',
    icon: 'BusinessIntelligence',
    showInMenu: true,
    topLevel: true,
    roles: Role.ViewAdminPages,
    component: <views.Admin />,
    subLinks: [
      {
        text: 'audit',
        path: '/audit',
        icon: 'Sustainability',
        roles: Role.ViewAdminPages,
        component: <views.Audit />,
        exact: true,
        showInMenu: true,
      },
      {
        text: 'feature-management',
        path: '/features',
        icon: 'PlusMinus',
        roles: Role.ViewFeatureManagement,
        component: <views.Feature />,
        exact: true,
        showInMenu: true,
      },
      {
        text: 'sub-portals',
        path: '/sub-portals',
        icon: 'OrgChart',
        roles: Role.RoleNameViewDealer,
        component: <views.SubPortals />,
        exact: true,
        showInMenu: true,
      },
      {
        text: 'intelligence-events',
        path: '/intelligence-events',
        icon: 'OrgChart',
        roles: Role.RoleNameViewAutomationRule,
        component: <views.IntelligenceEvents />,
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    text: 'real-estate-it',
    path: '/real-estate-it',
    icon: 'ConnectedBuilding',
    showInMenu: true,
    topLevel: true,
    roles: Role.AccessPreviewFeatures,
    subLinks: [
      {
        text: 'assets',
        path: '/assets',
        icon: 'Devices',
        roles: Role.RoleNameViewRealEstateItAsset,
        component: <views.RealEstateItAssets />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'asset-details',
            path: '/:assetId',
            component: <views.RealEstateItAssetDetails />,
            roles: Role.RoleNameViewRealEstateItAsset,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'locations',
        path: '/locations',
        icon: 'Maps',
        roles: Role.RoleNameViewRealEstateItAsset,
        component: <views.RealEstateItLocations />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'location-details',
            path: '/:placeId',
            component: <views.RealEstateItLocationsDetails />,
            roles: Role.RoleNameViewRealEstateItAsset,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'suppliers',
        path: '/suppliers',
        icon: 'EndUser',
        roles: Role.RoleNameViewRealEstateItAsset,
        component: <views.RealEstateItSuppliers />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'supplier',
            path: '/:supplierId',
            roles: Role.RoleNameViewRealEstateItAsset,
            component: <views.RealEstateItSuppliersDetails />,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
      {
        text: 'profiles',
        path: '/profiles',
        icon: 'Doc',
        roles: Role.RoleNameViewRealEstateItAsset,
        component: <views.RealEstateItProfiles />,
        exact: true,
        showInMenu: true,
        subLinks: [
          {
            text: 'profile',
            path: '/:profileId',
            component: <views.RealEstateItProfilesDetails />,
            roles: Role.RoleNameViewRealEstateItAsset,
            breadcrumbOptions: {
              hasCustomLabel: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/scan',
    component: <views.ScanStart />,
    showInMenu: false,
    layout: 'scan',
    exact: true,
    roles: Role.RoleNameViewAsset,
  },
  {
    path: '/scan/add',
    component: <views.ScanAdd />,
    showInMenu: false,
    layout: 'scan',
    exact: true,
    roles: Role.RoleNameEditAsset,
  },
  {
    path: '/scan/add/details',
    component: <views.ScanDetails />,
    showInMenu: false,
    layout: 'scan',
    exact: true,
    roles: Role.RoleNameEditAsset,
  },
  {
    path: '/scan/list/:assetId?',
    component: <views.ScanList />,
    showInMenu: false,
    layout: 'scan',
    roles: [Role.RoleNameViewAsset, Role.RoleNameEditAsset],
  },
  {
    text: 'support',
    path: '/support',
    icon: 'Support',
    component: <views.Support />,
    showInMenu: true,
    exact: true,
    roles: Role.RoleNameViewSupport,
  },
  {
    path: '/support/:id',
    exact: true,
    component: <views.TicketDetails />,
    roles: Role.RoleNameViewSupport,
  },
  {
    path: '/scan/swap',
    component: <views.SwapDevice />,
    showInMenu: false,
    layout: 'scan',
    exact: true,
    roles: Role.RoleNameViewAsset,
  },
  {
    path: '/scan/swap/qrscanner',
    component: <views.SwapScanView />,
    showInMenu: false,
    layout: 'scan',
    exact: true,
    roles: Role.RoleNameViewAsset,
  },
  {
    path: '/scan/swap/confirm',
    component: <views.ConfirmSwap />,
    showInMenu: false,
    layout: 'scan',
    exact: true,
    roles: Role.RoleNameViewAsset,
  },
  {
    // Candidate for removal. This does not work since the form requires an active subscription(?)
    path: '/public-support',
    component: <views.SupportForm />,
    showInMenu: false,
    layout: 'public',
  },
  {
    text: 'manage-navigation',
    path: '/navigation/manage',
    component: <views.ManageNavigation />,
    showInMenu: false,
    roles: [Role.RoleNameViewNavigation, Role.RoleNameEditNavigation],
  },
  {
    path: '/uiConfig/manage',
    component: <views.ManageUiConfiguration />,
    showInMenu: false,
    roles: [Role.RoleNameViewUiConfiguration, Role.RoleNameEditUiConfiguration],
  },
  {
    path: '/product-template/manage/:templateId?/:tab?',
    component: <views.ProductTemplateManage />,
    showInMenu: false,
    roles: [Role.RoleNameViewProductTemplate, Role.RoleNameEditProductTemplate],
    breadcrumbOptions: {
      hasCustomLabel: true,
    },
  },
];

const flatten = (routeItems: IRouteItem[], parentPath?: string) => {
  return routeItems.reduce((arr: IRouteItem[], r) => {
    if (r.subLinks?.length) {
      if (parentPath && r.subLinks) {
        r.path = `${parentPath}${r.path}`;
        arr.push(r);
      }
      arr = arr.concat(
        flatten(
          r.subLinks,
          parentPath && !r.subLinks ? `${parentPath}${r.path}` : r.path
        )
      );
    } else {
      r.path = parentPath ? `${parentPath}${r.path}` : r.path;
      arr.push(r);
    }
    return arr;
  }, []);
};

export const routePaths = (layout?: AtlasLayoutType) => {
  return flattenedRoutes.filter(r => r.layout === layout).map(r => r.path);
};

function addParents() {
  const addParent = (item: IRouteItem, parent?: IRouteItem) => {
    item.parentItem = parent;
    item.subLinks?.forEach(child => addParent(child, item));
  };

  routes.forEach(rootRoute => addParent(rootRoute));
}

addParents();

export const flattenedRoutes = flatten(routes);

export default routes;
