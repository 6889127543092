import { Button } from '@mui/material';
import { Column } from 'react-table';

export function button<T>(options: {
  id: string;
  header: string;
  accessor: (row: T) => string | number;
  onClick: (row: T) => void;
  defaultShow?: boolean;
  width?: number;
  variant: 'text' | 'outlined' | 'contained';
}): Column<T> {
  const {
    id,
    header,
    accessor,
    onClick,
    variant = 'contained',
    defaultShow: show = true,
    width,
  } = options;

  return {
    id,
    Header: header,
    accessor,
    show,
    Cell: row => (
      <Button
        onClick={() => onClick(row.original)}
        variant={variant}
        color="primary"
      >
        {row.value}
      </Button>
    ),
    width,
  };
}
